// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = window.STATIC_URL || '/';

import '@hotwired/turbo';

import './vendor/bootstrap';
import Offcanvas from 'bootstrap/offcanvas';
import SelectorEngine from 'bootstrap/dom/selector-engine';

/**
 * ------------------------------------------------------------------------
 * Main application
 * ------------------------------------------------------------------------
 */

window.addEventListener('load', () => {
  document.documentElement.classList.remove('no-js');

  // Initialise le composant Offcanvas pour le menu sur mobile
  SelectorEngine.find('.offcanvas').forEach((el) => new Offcanvas(el));
});
